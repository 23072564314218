var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-container" },
    [
      _c("div", { staticClass: "container-header" }, [
        _c(
          "p",
          { staticClass: "container-title" },
          [
            _c("span", { staticStyle: { "margin-right": "10px" } }, [
              _vm._v("退款单" + _vm._s(_vm.type == 1 ? "详情" : "处理"))
            ]),
            _vm.type == 1
              ? [
                  _vm.data.status === 1
                    ? _c("el-tag", { attrs: { size: "small" } }, [
                        _vm._v("退款中")
                      ])
                    : _vm._e(),
                  _vm.data.status === 2
                    ? _c(
                        "el-tag",
                        { attrs: { type: "danger", size: "small" } },
                        [_vm._v("退款失败")]
                      )
                    : _vm._e(),
                  _vm.data.status === 3
                    ? _c(
                        "el-tag",
                        { attrs: { type: "success", size: "small" } },
                        [_vm._v("退款成功")]
                      )
                    : _vm._e(),
                  _vm.data.status === 4
                    ? _c(
                        "el-tag",
                        { attrs: { type: "warning", size: "small" } },
                        [_vm._v("退款完成")]
                      )
                    : _vm._e(),
                  _vm.data.status === 5
                    ? _c(
                        "el-tag",
                        { attrs: { type: "success", size: "small" } },
                        [_vm._v("部分退款")]
                      )
                    : _vm._e(),
                  _vm.data.status === 6
                    ? _c(
                        "el-tag",
                        { attrs: { type: "danger", size: "small" } },
                        [_vm._v("退款异常")]
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "refund-order-info" },
        [
          _c(
            "p",
            {
              staticStyle: {
                "font-size": "15px",
                "font-weight": "600",
                "margin-bottom": "20px"
              }
            },
            [_vm._v(" 订单基本信息 ")]
          ),
          _c(
            "el-form",
            {
              attrs: { "label-width": "100px", size: "mini", model: _vm.data }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "order_no", label: "订单号：" } },
                [_vm._v(_vm._s(_vm.data.order_no))]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "goods_name", label: "商品名称：" } },
                [_vm._v(_vm._s(_vm.data.goods_name))]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "count", label: "购买数量：" } },
                [_vm._v(_vm._s(_vm.data.count))]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "coupon_money", label: "优惠金额：" } },
                [
                  _vm._v(
                    _vm._s(_vm._f("handlePrice")(_vm.data.coupon_money)) + "元"
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "order_price", label: "付款金额：" } },
                [
                  _vm._v(
                    _vm._s(_vm._f("handlePrice")(_vm.data.order_price)) + "元"
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "addGoods",
          staticStyle: { margin: "20px 0" },
          attrs: { "label-width": "130px", model: _vm.data, size: "small" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "退款原因：", prop: "reason" } },
            [_vm._v(" " + _vm._s(_vm.data.reasons) + " ")]
          ),
          _vm.data.case_id === 10
            ? _c(
                "el-form-item",
                { attrs: { label: "原因描述：", prop: "reason" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "35%" },
                    attrs: {
                      type: "textarea",
                      rows: 7,
                      maxlength: "200",
                      "show-word-limit": "",
                      placeholder: "请输入内容"
                    },
                    model: {
                      value: _vm.data.cause_content,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "cause_content", $$v)
                      },
                      expression: "data.cause_content"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.data.certificates && _vm.data.certificates.length
            ? _c(
                "el-form-item",
                { attrs: { label: "投诉凭证：", prop: "certificates" } },
                [
                  _vm._l(_vm.data.certificates, function(item) {
                    return _c("img", {
                      key: item,
                      staticStyle: { width: "120px", "margin-right": "20px" },
                      attrs: { src: item, alt: "" },
                      on: {
                        click: function($event) {
                          ;(_vm.previewVisible = true), (_vm.imgUrl = item)
                        }
                      }
                    })
                  }),
                  _c(
                    "el-dialog",
                    {
                      attrs: { visible: _vm.previewVisible },
                      on: {
                        "update:visible": function($event) {
                          _vm.previewVisible = $event
                        }
                      }
                    },
                    [_c("img", { attrs: { width: "100%", src: _vm.imgUrl } })]
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.data.refund_coupons && _vm.data.refund_coupons.length
            ? _c(
                "el-form-item",
                {
                  staticStyle: { width: "65%" },
                  attrs: { label: "问题卡密：", prop: "name" }
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { margin: "20px 0 10px" },
                      attrs: { data: _vm.tableData }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          "min-width": "5%"
                        }
                      }),
                      _vm.goods_type === "LINK"
                        ? [
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_link",
                                label: "订单链接",
                                "min-width": "50%"
                              }
                            })
                          ]
                        : _vm.goods_type === "PICTURE"
                        ? [
                            _c("el-table-column", {
                              attrs: {
                                prop: "goods_link",
                                label: "订单二维码",
                                "min-width": "20%"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _c("img", {
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            src: row.goods_link,
                                            alt: ""
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2195648786
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "unique_id",
                                label: "二维码编号",
                                "min-width": "30%"
                              }
                            })
                          ]
                        : [
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "订单卡密",
                                "min-width": "50%"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm.goods_type === "NUMBER_PASSWORD"
                                          ? _c("span", [
                                              _vm._v(_vm._s(row.ds_number))
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          " " + _vm._s(row.goods_password) + " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2246559268
                              )
                            })
                          ]
                    ],
                    2
                  ),
                  _vm.data.refund_coupons.length > 5
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { display: "block", margin: "auto" },
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.selectCardVisible = true
                            }
                          }
                        },
                        [_vm._v("查看更多 > ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.data.refund_money
            ? _c(
                "el-form-item",
                { attrs: { label: "退款金额：", prop: "refund_money" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("handlePrice")(_vm.data.refund_money)) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.data.predict_advance_money
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "预计退回预充金：",
                    prop: "predict_advance_money"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("handlePrice")(_vm.data.predict_advance_money)
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.data.advance_money
            ? _c(
                "el-form-item",
                { attrs: { label: "实际退回预充金：", prop: "advance_money" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("handlePrice")(_vm.data.advance_money)) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "备注：", prop: "remark" } }, [
            _vm._v(" " + _vm._s(_vm.data.remark) + " ")
          ]),
          _vm.data.refuse_content
            ? _c(
                "el-form-item",
                { attrs: { label: "驳回原因:", prop: "refuse_content" } },
                [
                  _c("editor", {
                    ref: "tinymce",
                    staticStyle: { width: "80%" },
                    attrs: {
                      onlyRead: _vm.type == 1,
                      init: _vm.editorInit,
                      height: _vm.editorInit.height,
                      showUpload: false
                    },
                    model: {
                      value: _vm.editData.refuse_content,
                      callback: function($$v) {
                        _vm.$set(_vm.editData, "refuse_content", $$v)
                      },
                      expression: "editData.refuse_content"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.data.fail_content
            ? _c(
                "el-form-item",
                { attrs: { label: "异常原因:", prop: "fail_content" } },
                [_vm._v(" " + _vm._s(_vm.data.fail_content) + " ")]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticStyle: { "margin-top": "50px" } },
            [
              _vm.type == 2
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.infoVisible = true
                        }
                      }
                    },
                    [_vm._v("确认退款 ")]
                  )
                : _vm._e(),
              _vm.type == 2
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.rejectVisible = true
                        }
                      }
                    },
                    [_vm._v("驳 回 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.toOrderRefund } }, [
                _vm._v("返 回")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看问题卡密",
            visible: _vm.selectCardVisible,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.selectCardVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { margin: "20px 0 10px" },
              attrs: { data: _vm.data.refund_coupons }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", "min-width": "5%" }
              }),
              _vm.goods_type === "LINK"
                ? [
                    _c("el-table-column", {
                      attrs: {
                        prop: "goods_link",
                        label: "订单链接",
                        "min-width": "50%"
                      }
                    })
                  ]
                : _vm.goods_type === "PICTURE"
                ? [
                    _c("el-table-column", {
                      attrs: {
                        prop: "goods_link",
                        label: "订单二维码",
                        "min-width": "20%"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("img", {
                                staticStyle: { width: "120px" },
                                attrs: { src: row.goods_link, alt: "" }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unique_id",
                        label: "二维码编号",
                        "min-width": "30%"
                      }
                    })
                  ]
                : [
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "订单卡密",
                        "min-width": "50%"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.goods_type === "NUMBER_PASSWORD"
                                ? _c("span", [_vm._v(_vm._s(row.ds_number))])
                                : _vm._e(),
                              _vm._v(" " + _vm._s(row.goods_password) + " ")
                            ]
                          }
                        }
                      ])
                    })
                  ]
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.selectCardVisible = false
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "退款确认", visible: _vm.infoVisible, width: "60%" },
          on: {
            "update:visible": function($event) {
              _vm.infoVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: { model: _vm.formData, "label-width": "90px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退回预充金:", prop: "advance_money" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "350px" },
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.formData.advance_money,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "advance_money", $$v)
                        },
                        expression: "formData.advance_money"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  ),
                  _vm.showTips
                    ? _c(
                        "p",
                        { staticStyle: { color: "#ff4d4f" } },
                        [
                          _vm._v(" 退款总金额不可大于原订单金额 "),
                          _c("MyToolTip", {
                            attrs: {
                              content:
                                "退款预充金不可大于客户整单的预充金金额（若该订单下已经存在退款成功的退款单，需要计算本次的退款预充金加上之前的退款预充金是否大于客户整单的预充金金额）"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注:" } },
                [
                  _c("editor", {
                    ref: "tinymce",
                    staticStyle: { width: "80%" },
                    attrs: {
                      init: _vm.editorInit,
                      height: _vm.editorInit.height,
                      showUpload: false
                    },
                    model: {
                      value: _vm.formData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "remark", $$v)
                      },
                      expression: "formData.remark"
                    }
                  }),
                  _c("div", { staticStyle: { width: "80%,height" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRefund }
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.infoVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退款驳回",
            visible: _vm.rejectVisible,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.rejectVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "editData",
              attrs: {
                model: _vm.editData,
                "label-width": "90px",
                rules: _vm.rejectRule
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因:", prop: "refuse_content" } },
                [
                  _c("editor", {
                    ref: "tinymce",
                    staticStyle: { width: "80%" },
                    attrs: {
                      init: _vm.editorInit,
                      height: _vm.editorInit.height,
                      showUpload: false
                    },
                    model: {
                      value: _vm.editData.refuse_content,
                      callback: function($$v) {
                        _vm.$set(_vm.editData, "refuse_content", $$v)
                      },
                      expression: "editData.refuse_content"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.rejectRefund } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.rejectVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "margin-top": "30px",
          background: "#f2f3f5",
          "line-height": "40px",
          padding: "0 15px"
        }
      },
      [
        _c(
          "p",
          {
            staticClass: "container-title",
            staticStyle: { "font-size": "15px", "font-weight": "600" }
          },
          [_vm._v(" 客户退款信息 ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }